<template>
    <div>
        <div class="img-container">
            <img :src="displayPicture" alt="Image" class="img-fluid"
                 :style="{maxWidth: maxWidth, ...(maxHeight ? {'object-fit': 'contain', 'max-height': maxHeight} : {})}"/>
            <div class="overlay">
                <a id="uploadPicLink" class="icon" title="Choisir une image" @click="triggerFileInput">
                    <font-awesome-icon :icon="['fas', 'camera']" class="camera-icon"/>
                </a>
            </div>
        </div>
        <input type="file" ref="hiddenFileInput" class="d-none" @change="handleFileUpload"/>
    </div>
</template>

<script>
    import {pictureSrc} from '@/util/image';

    export default {
        name: "UploadablePicture",
        props: {
            value: {
                type: File,
                default: () => null
            },
            illustrated: {
                type: Object,
                default: () => null
            },
            type: {
                type: String,
                required: true
            },
            maxWidth: {
                type: String,
                default: '256px'
            },
            maxHeight: {
                type: String,
                default: null
            }
        },
        data: () => ({
            displayPicture: null,
        }),
        methods: {
            triggerFileInput() {
                this.$refs.hiddenFileInput.click();
            },
            handleFileUpload() {
                let reader = new FileReader();

                reader.onload = e => {
                    this.displayPicture = e.target.result;
                };

                reader.readAsDataURL(this.$refs.hiddenFileInput.files[0]);
                this.$emit('input', this.$refs.hiddenFileInput.files[0]);
            },
            updateDisplayPicture() {
                this.displayPicture = this.illustrated
                    ? pictureSrc(this.illustrated, this.type)
                    : ('/img/' + this.type + '/default.png');
            }
        },
        watch: {
            illustrated(val) {
                this.illustrated = val;
                this.updateDisplayPicture();
            }
        },
        mounted() {
            this.updateDisplayPicture();
        }
    }
</script>

<style scoped>
    img {
        width: 50%;
        filter: drop-shadow(0 0 1rem rgba(0, 0, 0, 0.33));
    }

    /* Container needed to position the overlay. Adjust the width as needed */
    .img-container {
        position: relative;
        width: 100%;
        margin-bottom: 20px;
    }

    /* The overlay effect (full height and width) - lays on top of the container and over the image */
    .overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        transition: .3s ease;
    }

    /* When you mouse over the container, fade in the overlay icon*/
    .img-container:hover .overlay {
        opacity: 0.5;
    }

    /* The icon inside the overlay is positioned in the middle vertically and horizontally */
    .icon {
        color: white;
        font-size: 150px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        text-align: center;
        filter: drop-shadow(0 0 0.5rem black);
    }

    .camera-icon {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
    }

    #uploadPicLink {
        cursor: pointer;
        color: white;
    }
</style>