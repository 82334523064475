export const pictureSrc = (object, type) => '/img/'
    + type + '/'
    + (object.imageExtension ? object.id : 'default') + '.'
    + (object.imageExtension || 'png');

export const thumbnailSrc = (object, type) => '/img/'
    + type + '/thumbnails/'
    + (object.imageExtension ? object.id : 'default') + '.'
    + (object.imageExtension || 'png');

export default {pictureSrc, thumbnailSrc};